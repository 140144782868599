import React, { useEffect, useRef, useState } from 'react';
import { Container, Typography, Box, Grid, Paper, Button, Avatar, Card, CardContent } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import hero from './images/hero.png';
import icon1 from './images/Icon Container (1).png';
import icon2 from './images/Icon Container (2).png';
import icon3 from './images/Icon Container (3).png';
import icon4 from './images/Icon Container (4).png';
import icon5 from './images/Icon Container (5).png';
import icon6 from './images/Icon Container (6).png';
import icon from './images/Icon Container.png';
import icon7 from './images/Icon Container (7).png';
import icon8 from './images/Icon Container (8).png';
import icon9 from './images/Icon Container (9).png';
import arrow from './images/downarrow.png';
import avatar1 from './images/Image.png';
import avatar2 from './images/Image (1).png';
import icon10 from './images/Icon Container (10).png';
import icon11 from './images/Icon Container (11).png';
import icon12 from './images/Icon Container (12).png';
import icon13 from './images/Icon Container (13).png';
import icon14 from './images/Icon Container (14).png';
import icon15 from './images/Icon Container (15).png';
import backgroundImage from './images/AbstractDesign.png';

const useStyles = makeStyles((theme) => ({
  hidden: {
    opacity: 0,
    transform: 'translateY(20px)',
    transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
  },
  visible: {
    opacity: 1,
    transform: 'translateY(0)',
  },
  testimonials: {
    overflow: 'hidden',
    padding: '60px 0',
    background: '#000',
    position: 'relative',
    '&:before, &:after': {
      position: 'absolute',
      top: 0,
      width: '250px',
      height: '100%',
      content: '""',
      zIndex: 1,
    },
    '&:before': {
      left: 0,
      background: 'linear-gradient(to left, rgba(0, 0, 0, 0), #000)',
    },
    '&:after': {
      right: 0,
      background: 'linear-gradient(to right, rgba(0, 0, 0, 0), #000)',
    },
    '&:hover $testimonialsSlide': {
      animationPlayState: 'paused',
    },
  },
  testimonialsSlide: {
    display: 'flex',
    animation: '$slide 35s infinite linear',
    '& > div': {
      flex: '1 1 22%',
      minWidth: '280px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: '#000',
      color: '#fff',
      padding: '1rem',
      margin: '0 1rem',
      border: '1px solid #444',
      borderRadius: '8px',
    },
  },
  '@keyframes slide': {
    from: { transform: 'translateX(0)' },
    to: { transform: 'translateX(-100%)' },
  },
  arrow: {
    fontSize: '2rem', // Adjust size as needed
    color: 'white', // Adjust color as needed
    cursor: 'pointer', // Add cursor pointer for better UX
  },
  '@media screen and (max-width: 425px)': {
    testimonialsSlide: {
      animationDuration: '20s',
    },
  },
  '@media screen and (max-width: 320px)': {
    testimonialsSlide: {
      animationDuration: '15s',
    },
  },
}));

const Item = styled(Box)(({ theme, hasBackgroundImage }) => ({
  padding: theme.spacing(2),
  backgroundImage: hasBackgroundImage ? `url(${backgroundImage})` : 'none',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top left',
  backgroundColor: hasBackgroundImage ? 'transparent' : '#000',
}));

export default function Airaex() {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState({});

  const elementsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible((prev) => ({ ...prev, [entry.target.id]: true }));
          observer.unobserve(entry.target);
        }
      });
    });

    elementsRef.current.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      if (elementsRef.current) {
        elementsRef.current.forEach((element) => {
          if (element) {
            observer.unobserve(element);
          }
        });
      }
    };
  }, []);

  const rectangles = Array(19).fill(null);

  return (
    <Box sx={{ backgroundColor: '#0c0a0a' }}>
      <Container>
      <Box>
        <Item hasBackgroundImage>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} id="section1" className={`${classes.hidden} ${isVisible['section1'] ? classes.visible : ''}`} ref={(el) => (elementsRef.current[0] = el)}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                textAlign: { md: 'center', lg: 'left' },
                width: { sm: '100%', md: '100%', lg: '100%' },
                mt: '8rem',
                color: '#fff',
              }}
            >
              Digital Solutions{' '}
              <Typography variant="h3" component="span" sx={{ color: 'gray' }}>
                That Drive Success
              </Typography>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                textAlign: { md: 'center', lg: 'left' },
                width: { sm: '100%', md: '100%', lg: '100%' },
                color: '#808080',
                fontSize: '15px',
              }}
            >
              At AiraeX, we believe in the transformative power of digital solutions. Our team of
              experts is dedicated to helping businesses like yours thrive in the fast-paced digital
              landscape. From captivating web design to data-driven marketing strategies, we are
              committed to delivering results that exceed expectations.
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                textAlign: { md: 'center', lg: 'left' },
                width: { sm: '100%', md: '100%', lg: '100%' },
                // ml: { md: '20rem', lg: '0rem' },
                color: '#fff',
              }}
            >
              Unlock Your Digital Potential Today
            </Typography>
            <Box
              sx={{
                textAlign: { md: 'center', lg: 'left' },
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: { md: 150 },
                  backgroundColor: '#fff',
                  color: '#000',
                  borderRadius: '56px',
                }}
              >
                Reach Us
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ position: 'relative' }}>
            <Avatar
              alt="Remy Sharp"
              src={hero}
              sx={{
                width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
                height: '50%',
                mt: { xs: 0, md: '8rem' },
                position: 'relative',
              }}
              variant="square"
            />
          </Grid>
        </Grid>
        </Item>
      </Box>
    </Container>

<Container>
  <Typography 
    variant='h3' 
    sx={{ 
      textAlign: 'center', 
      mt: { md:'1rem', lg:'6rem' }, 
      mx: 'auto', // Center horizontally
      maxWidth: { xs: '90%', sm: 500, md: 900 }, // Max width for different screen sizes
      color: '#fff' 
    }}
  >
    Reasons to Choose AiraeX for Your Digital Journey
  </Typography>
  <Typography 
    variant="body2" 
    gutterBottom 
    sx={{ 
      textAlign: 'center', 
      mt: '1rem', 
      mx: 'auto', // Center horizontally
      maxWidth: { xs: '90%', sm: 500, md: 900 }, // Max width for different screen sizes
      color: '#808080' 
    }}
  >
    Partnering with AiraeX offers a multitude of advantages. Experience increased brand visibility, improved customer engagement, and higher ROI. Our tailored solutions are designed to meet your unique business needs, ensuring lasting success.
  </Typography>

  <Box sx={{ flexGrow: 1, mt: '3rem', display: 'flex', justifyContent: 'center' }}>
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} gutterBottom spacing={4} justifyContent="center">
      <Grid item xs={12} sm={4} md={4}>
        <Item hasBackgroundImage sx={{ textAlign: 'center', backgroundColor: '#000', borderRight: '1px solid rgba(31, 31, 31, 1)', borderBottom: '1px solid rgba(31, 31, 31, 1)' }} id="section2" className={`${classes.hidden} ${isVisible['section2'] ? classes.visible : ''}`} ref={(el) => (elementsRef.current[1] = el)}>
          <Avatar
            alt="Remy Sharp"
            src={icon1}
            sx={{ width: 50, height: 50, margin: 'auto' }}
          />
          <Typography variant='h6' color='#fff'>
            Expertise That Drives Results
          </Typography>
          <Typography variant='body2' color='#8C8C8C'>
            Our team of seasoned professionals brings years of experience and expertise to the table.
          </Typography>
        </Item>
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <Item hasBackgroundImage sx={{ textAlign: 'center', backgroundColor: '#000', borderRight: '1px solid rgba(31, 31, 31, 1)', borderBottom: '1px solid rgba(31, 31, 31, 1)' }} id="section3" className={`${classes.hidden} ${isVisible['section3'] ? classes.visible : ''}`} ref={(el) => (elementsRef.current[2] = el)}>
          <Avatar
            alt="Remy Sharp"
            src={icon2}
            sx={{ width: 50, height: 50, margin: 'auto' }}
          />
          <Typography variant='h6' color='#fff'>
            Tailored Business Solutions
          </Typography>
          <Typography variant='body2' color='#8C8C8C'>
            We understand that every business is unique. That's why our solutions are customized.
          </Typography>
        </Item>
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <Item hasBackgroundImage sx={{ textAlign: 'center', backgroundColor: '#000', borderRight: '1px solid rgba(31, 31, 31, 1)', borderBottom: '1px solid rgba(31, 31, 31, 1)' }} id="section3" className={`${classes.hidden} ${isVisible['section3'] ? classes.visible : ''}`} ref={(el) => (elementsRef.current[2] = el)}>
          <Avatar
            alt="Remy Sharp"
            src={icon3}
            sx={{ width: 50, height: 50, margin: 'auto' }}
          />
          <Typography variant='h6' color='#fff'>
            Cutting-Edge Web Design
          </Typography>
          <Typography variant='body2' color='#8C8C8C'>
            Leave a lasting impression on your audience with our top-notch web design services.
          </Typography>
        </Item>
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <Item hasBackgroundImage sx={{ textAlign: 'center', backgroundColor: '#000', borderRight: '1px solid rgba(31, 31, 31, 1)', borderBottom: '1px solid rgba(31, 31, 31, 1)' }} id="section3" className={`${classes.hidden} ${isVisible['section3'] ? classes.visible : ''}`} ref={(el) => (elementsRef.current[2] = el)}>
          <Avatar
            alt="Remy Sharp"
            src={icon4}
            sx={{ width: 50, height: 50, margin: 'auto' }}
          />
          <Typography variant='h6' color='#fff'>
            Mobile-First Approach
          </Typography>
          <Typography variant='body2' color='#8C8C8C'>
            In today's mobile-centric world, we prioritize mobile-first design to ensure your website.
          </Typography>
        </Item>
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <Item hasBackgroundImage sx={{ textAlign: 'center', backgroundColor: '#000', borderRight: '1px solid rgba(31, 31, 31, 1)', borderBottom: '1px solid rgba(31, 31, 31, 1)' }} id="section3" className={`${classes.hidden} ${isVisible['section3'] ? classes.visible : ''}`} ref={(el) => (elementsRef.current[2] = el)}>
          <Avatar
            alt="Remy Sharp"
            src={icon5}
            sx={{ width: 50, height: 50, margin: 'auto' }}
          />
          <Typography variant='h6' color='#fff' gutterBottom>
            Marketing Strategies
          </Typography>
          <Typography variant='body2' color='#8C8C8C'>
            Our data-driven marketing strategies allow us to target the right audience with precision.
          </Typography>
        </Item>
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <Item hasBackgroundImage sx={{ textAlign: 'center', backgroundColor: '#000', borderRight: '1px solid rgba(31, 31, 31, 1)', borderBottom: '1px solid rgba(31, 31, 31, 1)' }} id="section3" className={`${classes.hidden} ${isVisible['section3'] ? classes.visible : ''}`} ref={(el) => (elementsRef.current[2] = el)}>
          <Avatar
            alt="Remy Sharp"
            src={icon6}
            sx={{ width: 50, height: 50, margin: 'auto' }}
          />
          <Typography variant='h6' color='#fff' gutterBottom>
            Search Engine Optimization
          </Typography>
          <Typography variant='body2' color='#8C8C8C'>
            Boost your online visibility with our expert SEO techniques.
          </Typography>
        </Item>
      </Grid>
    </Grid>
  </Box>
</Container>


      <Container id="section4" className={`${classes.hidden} ${isVisible['section4'] ? classes.visible : ''}`} ref={(el) => (elementsRef.current[3] = el)}>
      <Typography variant='h3' sx={{ 
  textAlign: 'center', 
  mt: {xs:'7rem', md:'1rem', lg:'6rem' }, 
  mx: 'auto', // Center horizontally
  maxWidth: { xs: '90%', sm: 500, md: 900 }, // Max width for different screen sizes
  color: '#fff' 
}}>
 <Typography variant="h3" component='span' sx={{ color: 'gray' }}>Our</Typography> Services
</Typography>
<Typography variant="body2" gutterBottom sx={{ 
  textAlign: 'center', 
  mt: '1rem', 
  mx: 'auto', // Center horizontally
  maxWidth: { xs: '90%', sm: 500, md: 900 }, // Max width for different screen sizes
  color: '#808080' 
}}>
Our comprehensive range of services includes web design, mobile app development, SEO, social media marketing, and more. Whether you're a startup or an established enterprise, our experts will craft solutions that drive results.
</Typography>


  <Grid container spacing={2} columns={{ xs: 8, sm: 16 }} sx={{ mt: '1rem',textAlign:'center' }} id="section5" className={`${classes.hidden} ${isVisible['section5'] ? classes.visible : ''}`} ref={(el) => (elementsRef.current[4] = el)}>
    <Grid item xs={8} sm={8} md={8} lg={8}>
      <Item hasBackgroundImage sx={{ backgroundColor: '#000',mt: { xs: '3rem', sm: '6rem' }, ml: 'auto', mr: 'auto', maxWidth: 600 }}>
        <Avatar
          alt="Remy Sharp"
          src={icon}
          sx={{ width: 50, height: 50, mb: '2rem',mt:'1rem', mx: 'auto', display: 'block' }}
        />
        <Typography variant='h6' color='#fff' gutterBottom>
          Web Development
        </Typography>
        <Typography variant='body2' color='#8C8C8C' gutterBottom>
          Unlock Your Online Potential. In today's digital age, a powerful web presence is essential for any business. At AiraeX, our web development services empower you to stand out in the crowded online landscape. We create responsive and dynamic websites tailored to your unique needs, ensuring seamless user experiences across all devices. From e-commerce platforms to interactive web applications, our expert developers bring your vision to life, making your online journey a success.
        </Typography>
      </Item>
    </Grid>

    <Grid item xs={8} sm={8} md={8} lg={8}>
      <Item hasBackgroundImage sx={{ backgroundColor: '#000', mt: { xs: '3rem', sm: '6rem' }, ml: 'auto', mr: 'auto', maxWidth: 600 }}>
        <Avatar alt="Remy Sharp"
          src={icon7}
          sx={{ width: 50, height: 50, mb: '2rem',mt:'1rem', mx: 'auto', display: 'block' }}
        ></Avatar>
        <Typography variant='h6' color='#fff' gutterBottom>
          Mobile App Development
        </Typography>
        <Typography variant='body2' color='#8C8C8C' gutterBottom>
          Embrace Mobility with Confidence Mobile devices have revolutionized the way we interact with the world. Our mobile app development services enable you to harness this mobility to your advantage. We design and build intuitive and high-performance mobile applications that captivate your audience and boost engagement. Whether it's iOS, Android, or cross-platform development, we ensure that your app delivers a seamless experience, leaving a lasting impression on your users.
        </Typography>
      </Item>
    </Grid>

    <Grid item xs={8} sm={8} md={8} lg={8}>
      <Item hasBackgroundImage sx={{ backgroundColor: '#000', mt: { xs: '3rem', sm: '6rem' }, ml: 'auto', mr: 'auto', maxWidth: 600 }}>
        <Avatar alt="Remy Sharp"
          src={icon8}
          sx={{ width: 50, height: 50, mb: '2rem',mt:'1rem' , mx: 'auto', display: 'block'}}
        ></Avatar>
        <Typography variant='h6' color='#fff' gutterBottom>
          Web Design
        </Typography>
        <Typography variant='body2' color='#8C8C8C' gutterBottom>
          Elevate Your Brand Aesthetics Your website's design is a reflection of your brand's identity and values. AiraeX's web design services focus on creating visually striking and user-friendly interfaces that leave a lasting impact. Our creative team blends aesthetics with functionality, delivering a captivating user experience that keeps visitors coming back for more. Let us transform your online presence into an immersive journey that showcases your brand's true essence.
        </Typography>
      </Item>
    </Grid>

    <Grid item xs={8} sm={8} md={8} lg={8}>
      <Item hasBackgroundImage sx={{ backgroundColor: '#000', mt: { xs: '3rem', sm: '6rem' }, ml: 'auto', mr: 'auto', maxWidth: 600 }}>
        <Avatar alt="Remy Sharp"
          src={icon9}
          sx={{ width: 50, height: 50, mb: '2rem',mt:'1rem', mx: 'auto', display: 'block' }}
        ></Avatar>
        <Typography variant='h6' color='#fff' gutterBottom>
          Digital Marketing
        </Typography>
        <Typography variant='body2' color='#8C8C8C' gutterBottom>
        Elevate Your Brand Aesthetics Your website's design is a reflection of your brand's identity and values. AiraeX's web design services focus on creating visually striking and user-friendly interfaces that leave a lasting impact. Our creative team blends aesthetics with functionality, delivering a captivating user experience that keeps visitors coming back for more. Let us transform your online presence into an immersive journey that showcases your brand's true essence.
  </Typography>
      </Item>
    </Grid>
  </Grid>
</Container>



      <Box>
        <Container id="section6" className={`${classes.hidden} ${isVisible['section6'] ? classes.visible : ''}`} ref={(el) => (elementsRef.current[5] = el)}>
        <Typography variant='h3' sx={{ 
  textAlign: 'center', 
  mt: {xs:'7rem', md:'1rem', lg:'6rem' }, 
  mx: 'auto', 
  maxWidth: { xs: '95%', sm: 500, md: 900 }, 
  color: '#fff' 
}}>
  <Typography variant="h3" component='span' sx={{ color: 'gray' }}>Our</Typography> Testimonials
</Typography>

<Typography variant="body2" gutterBottom sx={{ 
  textAlign: 'center', 
  mt: '1rem', 
  mx: 'auto', 
  mb:'2rem',
  maxWidth: { xs: '90%', sm: 500, md: 900 }, 
  color: '#808080' 
}}>
Don't just take our word for it; hear what our satisfied clients have to say about their experience with AiraeX. We take pride in building lasting relationships and delivering exceptional results.
</Typography>

  </Container>

        <Box className={classes.testimonials}>
          <Box className={classes.testimonialsSlide}>
            {Array.from({ length: 80 }).map((_, index) => (
              <Box key={index}>
                <Card sx={{ width: '100%', backgroundColor: '#000' }}>
                  <CardContent>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#fff"
                        d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.2 4.2 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.52 8.52 0 0 1-5.33 1.84q-.51 0-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23"
                      />
                    </svg>
                    <Typography variant="body2" color="#fff" sx={{ mt: '1rem' }}>
                      Working with AiraeX was a pleasure. Their web design team created a stunning website that perfectly captured our brand's essence. The feedback from our customers has been overwhelmingly positive.
                    </Typography>
                  </CardContent>
                </Card>
                <Avatar
                  alt="Arrow"
                  src={arrow}
                  sx={{ width: 50, height: 50, mt: '-1rem', ml: '-14rem' }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', mt: '1rem' }}>
                  <Avatar
                    alt="Remy Sharp"
                    src={index % 2 === 0 ? avatar1 : avatar2}
                    sx={{ width: 50, height: 50 }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      color: '#fff',
                      ml: '1rem',
                    }}
                  >
                    <Typography variant="body1">Mark Roberts</Typography>
                    <Typography variant="body2">
                      Founder of GreenEarth Eco Store
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>


      <Container  id="section7" className={`${classes.hidden} ${isVisible['section7'] ? classes.visible : ''}`} ref={(el) => (elementsRef.current[6] = el)}>
      <Typography variant='h3' sx={{ 
  textAlign: 'center', 
  mt: { xs:'7rem',md:'1rem', lg:'6rem' }, 
  mx: 'auto', // Center horizontally
  maxWidth: { xs: '90%', sm: 500, md: 900 }, // Max width for different screen sizes
  color: '#fff' 
}}>
 <Typography variant="h3" component='span' sx={{ color: 'gray' }}>Our</Typography> Partners and Clients
</Typography>
<Typography variant="body2" gutterBottom sx={{ 
  textAlign: 'center', 
  mt: '1rem', 
  mx: 'auto', // Center horizontally
  maxWidth: { xs: '90%', sm: 500, md: 900 }, // Max width for different screen sizes
  color: '#808080' 
}}>
We are grateful for the opportunity to work with esteemed partners and clients. Our strong relationships are a testament to our dedication and expertise in the digital realm.
</Typography>



        <Box sx={{ flexGrow: 1, mt: '3rem' }} id="section8" className={`${classes.hidden} ${isVisible['section8'] ? classes.visible : ''}`} ref={(el) => (elementsRef.current[7] = el)}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={2} gutterBottom>

            <Grid item xs={2} sm={4} md={4}  >
              <Item hasBackgroundImage sx={{ textAlign: 'center', backgroundColor: '#000' }}>

              <Box
  sx={{
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' }, // Change flex direction for smaller screens
    justifyContent: 'center', // Center align items horizontally
    alignItems: 'center', // Center align items vertically
    mt: { xs: '1rem', sm: '2rem' }, // Adjust margin-top for smaller screens
  }}
>
  <Avatar alt="Remy Sharp" src={icon10} sx={{ width: 50, height: 50 }} />
  <Button sx={{ color: '#fff', mx: '0.5rem' }}>+</Button> {/* Add margin between Avatar and Button */}
  <Avatar alt="Remy Sharp" src={icon11} sx={{ width: 50, height: 50 }} />
</Box>
                <Box sx={{ mt: '2rem' }}>
                  <Typography variant='h6' color='#fff'>
                    ABC Tech Solutions
                  </Typography>
                  <Typography variant='body2' color='#8C8C8C'>
                    Our team of seasoned professionals brings years of experience and expertise to the table.
                  </Typography>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={2} sm={4} md={4}  >
              <Item hasBackgroundImage sx={{ textAlign: 'center', backgroundColor: '#000' }}>
              <Box
  sx={{
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' }, // Change flex direction for smaller screens
    justifyContent: 'center', // Center align items horizontally
    alignItems: 'center', // Center align items vertically
    mt: { xs: '1rem', sm: '2rem' }, // Adjust margin-top for smaller screens
  }}
>
  <Avatar alt="Remy Sharp" src={icon12} sx={{ width: 50, height: 50 }} />
  <Button sx={{ color: '#fff', mx: '0.5rem' }}>+</Button> {/* Add margin between Avatar and Button */}
  <Avatar alt="Remy Sharp" src={icon13} sx={{ width: 50, height: 50 }} />
</Box>
                <Box sx={{ mt: '2rem' }}>
                  <Typography variant='h6' color='#fff'>
                    GreenEarth Eco Store
                  </Typography>
                  <Typography variant='body2' color='#8C8C8C'>
                    Our team of seasoned professionals brings years of experience and expertise to the table.
                  </Typography>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={2} sm={4} md={4}  >
              <Item hasBackgroundImage sx={{ textAlign: 'center', backgroundColor: '#000' }}>
              <Box
  sx={{
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' }, // Change flex direction for smaller screens
    justifyContent: 'center', // Center align items horizontally
    alignItems: 'center', // Center align items vertically
    mt: { xs: '1rem', sm: '2rem' }, // Adjust margin-top for smaller screens
  }}
>
  <Avatar alt="Remy Sharp" src={icon14} sx={{ width: 50, height: 50 }} />
  <Button sx={{ color: '#fff', mx: '0.5rem' }}>+</Button> {/* Add margin between Avatar and Button */}
  <Avatar alt="Remy Sharp" src={icon15} sx={{ width: 50, height: 50 }} />
</Box>
                <Box sx={{ mt: '2rem' }}>
                  <Typography variant='h6' color='#fff'>
                    HealthTech Innovations
                  </Typography>
                  <Typography variant='body2' color='#8C8C8C'>
                    Our team of seasoned professionals brings years of experience and expertise to the table.
                  </Typography>
                </Box>
              </Item>
            </Grid>

          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
