import React from 'react';
import Link from '@mui/material/Link';
import { Container, Typography, Box, Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import icon16 from './images/Button.png';
import icon17 from './images/Button (1).png';
import icon18 from './images/Button (2).png';
import location from './images/location.png';
import IconButton from '@material-ui/core/IconButton';
import {
    IconFlagIN,
    IconFlagUK
} from 'material-ui-flags';
import backgroundImage from './images/AbstractDesign.png';

export default function Footer() {
  return (
<Box 
      sx={{
        backgroundColor: '#0c0a0a',
        padding: '2rem',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '86px',
        mt: '6rem',
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'repeat-x',
      }}
    >
      <Container
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: '0.9px solid',
          borderImageSource: 'linear-gradient(180deg, #262626 0%, rgba(38, 38, 38, 0) 100%)',
          borderImageSlice: 1,
          pointerEvents: 'none',
        }}
      />
      <Container>
        <Typography 
          variant='h3' 
          sx={{ 
            width: { sm: '100%', lg: '100%' }, 
            textAlign: 'center', 
            mt: '4rem', 
            // ml: { xs: '0rem', sm: '-2rem', md: '6rem', lg: '8rem', xl: '10rem' }, 
            color: '#fff' 
          }}
        >
          Ready to Transform Your Digital Presence?
        </Typography>
        <Typography 
          variant="body2" 
          gutterBottom 
          sx={{ 
            width: { sm: '100%', lg: '100%' }, 
            textAlign: 'center', 
            mt: '1rem', 
            // ml: { xs: '0rem', sm: '0rem', md: '6rem', lg: '8rem', xl: '10rem' }, 
            color: '#808080' 
          }}
        >
          Take the first step towards digital success with AiraeX by your side. Our team of experts is eager to craft tailored solutions that drive growth for your business. Whether you need a stunning website, a powerful mobile app, or a data-driven marketing campaign, we've got you covered. Let's embark on this transformative journey together.
        </Typography>
      </Container>

      <Container>
  <Grid container spacing={2} sx={{ mt: '4rem', justifyContent: 'center', alignItems: 'center' }}>
    <Grid item xs={12} md={8} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Avatar 
        alt="Location"
        src={location}
        sx={{ 
          width: { xs: '100%', sm: 450 }, 
          height: { xs: 'auto', sm: 350 }
        }}
        variant="square"
      />
    </Grid>
    <Grid item xs={12} md={4} sx={{ mt: { xs: '2rem', md: '0' }, textAlign: { xs: 'center', md: 'center' }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ mb: '2rem', textAlign: { xs: 'center'} }}>
        <Typography variant='body2' color='#fff'>
          <IconButton><IconFlagUK /></IconButton>England, United Kingdom
        </Typography>
        <Typography variant="body1" color="#fff">Hill Top, Rivington Road,</Typography>
        <Typography variant="body1" color="#fff">Blackburn with Darwen,</Typography>
        <Typography variant="body1" color="#fff">BL7 8AB</Typography>
      </Box>
      <Box sx={{ textAlign: { xs: 'center' } }}>
        <Typography variant='body2' color='#fff'>
          <IconButton><IconFlagIN /></IconButton>Chennai, India
        </Typography>
        <Typography variant="body1" color="#fff">RMZ Millenia Business Park,</Typography>
        <Typography variant="body1" color="#fff">MGR Main Road No.143,</Typography>
        <Typography variant="body1" color="#fff">6th Floor, Phase 2, Campus 4B,</Typography>
        <Typography variant="body1" color="#fff">Chennai, India 600096</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: '5rem' }}>
        <Avatar alt="Icon 16" src={icon16} sx={{ width: 50, height: 50, mx: 3 }} />
        <Avatar alt="Icon 17" src={icon17} sx={{ width: 50, height: 50, mx: 3 }} />
        <Avatar alt="Icon 18" src={icon18} sx={{ width: 50, height: 50, mx: 3 }} />
      </Box>
    </Grid>
  </Grid>
</Container>


<Container
  sx={{
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    alignItems: 'center',
    justifyContent: 'center',
    gap: { xs: 2, sm: 4, md: 8 },
    py: { xs: 6, sm: 8, md: 10 },
    textAlign: 'left',
    color: '#fff',
    ml: { xs: 0, lg: '5.5rem' }
  }}
>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      textAlign: { xs: 'center', sm: 'left', lg: 'left' },
      ml: { xs: '1rem', sm: '2rem', md: '3rem', lg: '1rem' },
    }}
  >
    <Typography variant="body2" fontWeight={600}>
      Services
    </Typography>
    <Link color="#fff" href="#" sx={{ textDecoration: 'none' }}>
      Web Design
    </Link>
    <Link color="#fff" href="#" sx={{ textDecoration: 'none' }}>
      Website Development
    </Link>
    <Link color="#fff" href="#" sx={{ textDecoration: 'none' }}>
      App Development
    </Link>
    <Link color="#fff" href="#" sx={{ textDecoration: 'none' }}>
      Digital Marketing
    </Link>
  </Box>

  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      textAlign: { xs: 'center', sm: 'left', lg: 'left' },
      ml: { xs: '1rem', sm: '2rem', md: '3rem', lg: '1rem' },
      mt: { xs: '2rem', sm: '0', md: '0', lg: '-1.5rem' }
    }}
  >
    <Typography variant="body2" fontWeight={600}>
      About Us
    </Typography>
    <Link color="#fff" href="#" sx={{ textDecoration: 'none' }}>
      Our Team
    </Link>
    <Link color="#fff" href="#" sx={{ textDecoration: 'none' }}>
      Achievements
    </Link>
    <Link color="#fff" href="#" sx={{ textDecoration: 'none' }}>
      Awards
    </Link>
  </Box>

  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      ml: { xs: '1rem', sm: '0', md: '0', lg: '12rem' }, 
      mt: { xs: '2rem', sm: '0', md: '0', lg: '-4rem' } 
    }}
  >
    <Typography variant="h2">
      Airae
      <Typography variant="h2" component="span" sx={{ color: 'gray' }}>
        X
      </Typography>
    </Typography>
  </Box>
</Container>



      <Container sx={{ mt: '10rem' }}>
        <hr />
      </Container>

      <Container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          color: '#fff',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="body2" component="span" sx={{ display: { xs: 'block', sm: 'block', md: 'inline' } }}>
          @2024 AiraeX. All Rights Reserved.
        </Typography>
        <Typography variant="body2" component="span">
          Version 1.0
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          <Typography variant="body2" component="span">
            Privacy Policy
          </Typography>
          <Typography variant="body2" component="span">
            Terms & Conditions
          </Typography>
          <Typography variant="body2" component="span">
            Cookie Policy
          </Typography>
        </Box>
      </Container>
    </Box>
  )
}
