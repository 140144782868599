import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';


const pages = ['Home', 'Services', 'About Us', 'Contact Us'];

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'transparent' }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                fontWeight: 600,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Airae<Typography variant="h5" component='span' sx={{ color: 'gray', fontWeight: 600 }}>X</Typography>
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                fontWeight: 600,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Airae<Typography variant="h5" component='span' sx={{ color: 'gray', fontWeight: 600 }}>X</Typography>
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
            <Stack direction='row' spacing={2}>
              {pages.map((page) => (
                <Button
                  key={page}
                  color="inherit"
                  variant={page === 'Home' ? "outlined" : "text"} // Use outlined variant only for Home
                  sx={{
                    borderBottom: '2px solid transparent', // Add initial transparent underline
                    '&:hover': {
                      borderBottom: '2px solid #fff', // Change underline color on hover
                    },
                  }}
                >
                  {page}
                </Button>
              ))}
            </Stack>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            PaperProps={{
              sx: {
                opacity: 1,
                transform: 'none',
                transition: 'opacity 309ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 206ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                top: '52px',
                width: '100%',
                transformOrigin: '109px 0px',
                backgroundColor: '#242222', // Added background color
                color: '#fff', // Added text color
              },
            }}
            MenuListProps={{
              sx: {
                listStyle: 'none',
                margin: 0,
                padding: 0,
                position: 'relative',
                paddingTop: '8px',
                paddingBottom: '8px',
                outline: 0,
                backgroundColor: '#242222', // Added background color
                color: '#fff', // Added text color
              },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu} sx={{ width: '100%' }}>
                <Button
                  fullWidth
                  color="inherit"
                  variant={page === 'Home' ? "outlined" : "text"} // Use outlined variant only for Home
                  sx={page === 'Home' ? { borderColor: '#1c1c1d'  } : { color: '#fff' }}
                >
                  {page}
                </Button>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
