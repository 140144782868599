import React from 'react';
import { Container,Box} from '@mui/material';
import Header from './Components/header/Header';
import Airaex from './Airae/Airaex';
import Footer from './Components/footer/Footer';

export default function App() {
  return (
   <Box sx={{ backgroundColor: '#0c0a0a',fontFamily:'Sora' }}>
 <Header/>
      <Airaex/>
      <Footer/>

   </Box>
     
   
  )
}
